.editor-text {
    padding: 100px;
    color: rgb(151, 153, 155);
}

.task_Work_main-cls-button {
    font-size: 14px !important;
    transition: background-color 0.3s ease;

}

.task_Work_main-cls-button:hover {
    background-color: rgb(231, 237, 254) !important;

}

/* .task_Work_main-cls-button .MuiButton-endIcon {
    background-color: #60a630;
} */

/* .task_Work_main-cls-button .MuiButton-endIcon .MuiSvgIcon-root {
    color: white;
} */


.task_Work-button_save {
    background-color: #00AC56 !important;
}

.task_Work-button_cancel {
    border-color: #00AC56 !important;
    color: #00AC56 !important
}

.task_Work_textfield .MuiOutlinedInput-root {
    border-color: transparent;
    background-color: #f5f5f5;
}

.task_work_Label .MuiOutlinedInput-root {
    border-color: transparent;
}

.task_work_Label {
    width: 200px;
    height: 40px;
}

.task_work_Label .MuiOutlinedInput-root {
    height: 100%;
    border-color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
}

.task_work_Label .MuiOutlinedInput-notchedOutline {
    border-color: transparent;
}

/* .task_Work_textfield .MuiOutlinedInput-notchedOutline {
    border-color: transparent;
} */

.task_Work_textfield {
    width: 200px;
    height: 40px;
}

.css-3s53ew-MuiPaper-root-MuiPopover-paper {
    box-shadow: none !important;
}

.task_Work_textfield .MuiOutlinedInput-root {
    height: 100%;
    border-color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f5f5f5;
}

.task_Work_textfield .MuiOutlinedInput-notchedOutline {
    border-color: transparent;
}

.task_Work_Update_textfield {
    /* display: flex;
    justify-content: space-between;
    align-items: center; */
    padding-bottom: 10px;
}

.task_Work_Update_editable-text {
    cursor: pointer;
    width: 100%;
    padding-left: 5px;


}

.task_Work_Update_editable-text-text {
    cursor: pointer;
    /* width: 60%; */
    padding-left: 5px;
    font-weight: 600 !important;
    font-size: 20px !important;
}

.task_Work_li-list {
    font-size: 13px;
    margin-top: 8px;
}


.textfield_Description {
    /* width: 96%; */
    width: 100%;
}

.editable-box {
    /* width: 95%; */
}

.textfield_Description .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
    border-color: transparent;
}

.textfield_Description .MuiInputBase-input {
    padding: 4px 8px;
    font-size: 14px;
    min-height: 30px;
    padding-left: 20px;
}

.test-cases-editable-box {

    ol,
    ul,
    p {
        margin-bottom: 0.4rem !important;
    }
}




.task_work_uncoveredBtn {
    background-color: #2E67AA !important;
    margin-top: 15px !important;
    border-radius: 10px !important;
    width: 280px;
    height: 22px;
    font-size: 15px;
    font-weight: bold;
    color: white !important;
    margin-top: 16px;
}

.task_work_uncoveredBtn:hover {
    background-color: #2E67AA !important;
}


.task_Work_typography-small {
    font-size: 13px;
}

.task_Work_avatar-small {
    width: 24px !important;
    height: 24px !important;
}

.task_Work_quick-start-box {
    display: flex;
    background-color: #dfdbf0;
    padding: 16px;
    /* 2 * 8px */
    border-radius: 4px;
    /* 1 * 4px */
    margin-top: 16px;
    /* 2 * 8px */
}

.task_Work_quick-start-icon {
    margin-right: 8px;
    /* 1 * 8px */
    margin-top: 4px;
}

.task_Work_quick-start-title {
    margin-bottom: 8px;
}

.task_Work_learn-more {
    color: blue;
}

.task_Work_fontsize {
    font-size: 12px;
}









.custom-autocomplete-input .MuiOutlinedInput-notchedOutline {
    border: none;
}

.custom-autocomplete-input .Mui-focused .MuiOutlinedInput-notchedOutline {
    border: 1px solid #60A630;
}

.custom-autocomplete-input .MuiInputBase-root {
    padding: 0;
}

/* .task_Work_avatar-small {
    width: 24px;
    height: 24px;
  } */

/* .task_Work_typography-small {
    font-size: 10px;
  } */

.d-flex {
    display: flex;
}

.align-items-center {
    align-items: center;
}

.ml-2 {
    margin-left: 8px;
}




.btnCommentGroup-smallBtn {
    background-color: #F4F5F7 !important;
    border: 0px !important;
    color: #000000 !important;
    font-size: 10px;
    font-weight: 500;
    text-transform: none !important;
    min-width: 10px !important;
    min-height: 5px !important;
    padding: 1px 5px !important;
    font-size: 14px !important;
}

.labelCustomize_btn {
    background-color: white !important;
    color: black !important;
    border-color: transparent !important;
    width: 30px !important;
    min-width: 30px !important;
    height: 30px !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    font-size: 16px !important;
}

.active-button {
    background-color: #00AC56 !important;
    color: white !important;
    font-weight: 500px !important;
    border: 0px !important;
}

.labelCustomize_btn:hover {
    background-color: #f5f5f5 !important;
}

.history_btn {
    height: 25px;
    line-height: 1;
    padding: 0 5px;
}

.bold {
    font-weight: bold !important;
}

.done {
    background-color: #E2FCF0 !important;
    color: black !important;
}

.inProgress {
    background-color: #E7EDFE !important;
    color: #0055CC !important;
}

.pill-badge {
    display: inline-block !important;
    padding: 0.1em 1em !important;
    border-radius: 9999px !important;
    /* background-color: #4191e6 !important; */
    color: white !important;
    font-size: 0.875rem !important;
    margin: 0.2em !important;
    text-align: center !important;
}

.selected-badge {
    display: inline-block !important;
    padding: 0.1em 1em !important;
    border-radius: 9999px !important;
    color: white !important;
    font-size: 0.875rem !important;
    margin: 0.2em !important;
    text-align: center !important;
}

.mention {
    /* font-weight: 600 !important; */
    color: #007bff !important;
    font-size: small !important;
}

.Linkedissue-checkbox .MuiSvgIcon-root {
    font-size: 20px !important;
    margin-left: 10px !important;
}

.Linkissue-main {
    width: 530px !important;
}

.Linkissue-name {
    color: rgb(52, 160, 227) !important;
    font-size: 14px !important;
    font-weight: 600 !important;
}

.Linkissue-summary {
    font-size: 14px !important;
    color: #333 !important;
}

.imageView-modal {
    position: absolute !important;
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%, -50%) !important;
    width: 90% !important;
    height: 90% !important;
    background-color: var(--background-paper) !important;
    padding: 16px !important;
    display: flex !important;
    flex-direction: column !important;
    align-items: flex-end !important;
}

.modal-close-btn {
    position: absolute !important;
    top: 8px !important;
    right: 8px !important;
    background-color: white !important;
    color: black !important;
}


.issue-card {
    display: flex !important;
    justify-content: space-between !important;
    /* width: 825px !important; */
    align-items: center !important;
    border: 1px solid #ccc !important;
    margin-bottom: 1px !important;
    border-radius: 5px !important;
    background-color: white !important;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.10) !important;
    position: relative;
}

.issue-card:hover {
    background-color: #f0f0f0 !important;
}

.unlinked {
    padding: 3px !important;
    max-width: 5px !important;
    min-width: 26px !important;
    color: black !important;
    opacity: 0;
    transition: opacity 0.3s ease;
    position: absolute;
    right: 2px;
}

.issue-card:hover .unlinked {
    opacity: 1 !important;
}

.notification-modal {
    width: 400px !important;
    height: 100vh !important;
    background-color: white !important;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1) !important;
    overflow-y: auto !important;
    position: absolute !important;
    right: 0 !important;
    top: 0 !important;
    z-index: 1200 !important;
}

.notification-card:hover {
    background-color: #6de1a769 !important;
}

.notify-close {
    /* display: flex !important; */
    /* justify-content: space-between !important; */
    background-color: #6de1a7 !important;
    padding: 18px !important;
    margin: 0px !important;
    font-size: larger !important;

}

.notify-focused {
    padding: 2px !important;
    display: flex !important;
    justify-content: space-between !important;
}

.notify-focused Button {
    background-color: transparent !important;
    font-weight: bold !important;
    color: rgb(43, 107, 47) !important;
    width: 100% !important;
    border: none !important;
}

.notify-focused Button:hover {
    background-color: transparent !important;
    border: none !important;
    font-weight: bold !important;
}

.notification-card {
    display: flex !important;
    align-items: center !important;
    padding: 16px !important;
    margin-bottom: 8px !important;
    background-color: white !important;
    border-radius: 4px !important;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.12), 0px 1px 2px rgba(0, 0, 0, 0.24) !important;
    cursor: pointer !important;
}

.ticket-id-share-icons hr {
    margin-left: -20px;
    margin-right: -20px;
    color: #EEEEEF;
    border-width: 2px;

}

.border-right {
    border-right: solid 2px #EEEEEF;
}

.ExpandMoreIcon {
    /* background-color: red; */
}

.customAccordion .accordion-summary {
    background-color: #D5DCE5 !important;
    min-height: 30px !important;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;

}

.customAccordion .accordion-details {
    background-color: #F4F6F9 !important;

}

.customAccordion .accordion-summary .Mui-expanded {
    margin: 15px 0 !important;
}



.assingeeBtnDropdownBacklog_main .onAvatar {
    height: 30px !important;
    width: 30px !important;
}


.assingeeBtnDropdownBacklog_main .onAvatar svg {
    width: 60%;
    height: 60%;
}

.test-cases-container {
    background-color: #F4F6F9;
    border-radius: 8px;
    padding: 15px;

}

@media screen and (max-width:600px) {
    .notification-drawer-cls [class*="JoyDrawer-content"] {
        width: 90% !important;
    }

    .notification-drawer-cls [class*="MuiDrawer-content"] {
        width: 90% !important;
    }

    .notification-drawer-cls button[class*="MuiSvgIcon"] {
        font-size: 1.2rem !important;
    }
}

@media screen and (min-width:601px) and (max-width:920px) {
    .notification-drawer-cls [class*="JoyDrawer-content"] {
        width: 70% !important;
    }

    .notification-drawer-cls [class*="MuiDrawer-content"] {
        width: 70% !important;
    }

    .notification-drawer-cls button[class*="MuiSvgIcon"] {
        font-size: 1.4rem !important;
    }
}

@media screen and (min-width:921px) and (max-width:1200px) {
    .notification-drawer-cls [class*="JoyDrawer-content"] {
        width: 50% !important;
    }

    .notification-drawer-cls [class*="MuiDrawer-content"] {
        width: 50% !important;
    }

    .notification-drawer-cls button[class*="MuiSvgIcon"] {
        font-size: 1.5rem !important;
    }
}

@media screen and (min-width:1201px) and (max-width:1400px) {
    .notification-drawer-cls [class*="JoyDrawer-content"] {
        width: 40% !important;
    }

    .notification-drawer-cls [class*="MuiDrawer-content"] {
        width: 40% !important;
    }

    .notification-drawer-cls button[class*="MuiSvgIcon"] {
        font-size: 1.5rem !important;
    }
}