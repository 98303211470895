.view-reports-outerBox {
    width: 100%;
    height: max-content;
    min-height: 100vh;
    padding: 24px;
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.accordion-cls{
    display: flex;
    justify-content: center;
    align-items: center;
}

.FontSize {
    font-weight: bold !important;
}

.project-reports-accordian-summary-cls {
    @media screen and (max-width:700px) {
        gap: 0px !important;
        width: initial !important;

    }

    @media screen and (min-width:701px) and (max-width:992px) {
        gap: 10px !important;
        width: 70% !important;

    }
}