 .backlog-page-main-container,
 .taskworkflow-page-main-container,
 .reports-page-container-cls,
 .list-page-container-cls,
 .taskworkflow-model-body,
 .search-bar {
     ::-webkit-scrollbar {
         width: 9px !important;
     }

     ::-webkit-scrollbar-track {
         -webkit-box-shadow: inset 0 0 18px rgba(162, 161, 160, 0.3) !important;
         -webkit-border-radius: 6px;
     }

     ::-webkit-scrollbar-thumb {
         -webkit-border-radius: 6px;
         border-radius: 8px;
         background: #a0a0a0;
         width: 8px !important;
     }

     ::-webkit-scrollbar-thumb:hover {
         background: #6c6c6c;
     }

     .crumb-class-name:hover {
         text-decoration: underline !important;
         color: #686868 !important;
     }

     ::-webkit-scrollbar:horizontal {
         height: 5px !important;
     }

     ::-webkit-scrollbar-thumb:horizontal {
         border-radius: 5px;
     }
 }