/* sx={{height:120 , display:'flex', flexDirection:'column' , gap:'10px', overflowY:'scroll',padding:'10px', boxShadow:"inherit" , borderColor:"red" */


.outer-Box{
    padding:10px;
    border-radius: 12px;
}
.Pie-chart-Box{
 height:100px; 
width: 100%;
display: flex;
flex-direction: column;
gap: 10px;
overflow-y: scroll; 
background-color: #FFFFFF;

}
/* ::-webkit-scrollbar{
    display: none;
} */