// table styles //
.table-container {
    overflow: hidden;
    border-radius: 15px; // Apply your desired radius here

    table {
        width: 100%;
        border-collapse: collapse;

        thead {
            tr {
                &:hover {
                    background-color: transparent !important;
                    border-bottom: none !important;
                }

                &:first-child {
                    th {
                        &:first-child {
                            border-top-left-radius: 15px;
                        }

                        &:last-child {
                            border-top-right-radius: 15px;
                        }
                    }
                }

                th {
                    font-size: 16px !important;
                    font-weight: 700 !important;
                    line-height: 22px !important;
                    color: #010101 !important;

                }
            }
        }

        tbody {
            tr {
                border-bottom: 1px solid #D5DCE5 !important;

                td {
                    padding-top: 12px !important;
                    padding-bottom: 12px !important;
                    // font-size: 16px !important;
                    font-weight: 400 !important;
                    line-height: 22px !important;
                    color: #010101 !important;

                }

                .project-title-cls {
                    color: #60A630 !important;
                }


                &:hover {
                    background-color: transparent !important;
                    border-bottom: 1px solid #9d9e9f !important;

                    .project-title-cls {
                        text-decoration: underline;
                    }
                }

                &:last-child {
                    border-bottom: none !important;

                    &:hover {
                        border-bottom: none !important;
                    }

                    td {
                        &:first-child {
                            border-bottom-left-radius: 15px;
                        }

                        &:last-child {
                            border-bottom-right-radius: 15px;
                        }
                    }
                }



            }
        }
    }

}

.table-pagination-container-cls {
    [class*="MuiPaginationItem-root"] {
        background-color: #60A630 !important;
        color: #ffffff !important;
        font-weight: 500 !important;
        font-size: 16px !important;
        border: 0px !important;
        border-radius: 4px !important;
        width: 26px !important;
        height: 26px !important;
        opacity: 0.5;

        &.Mui-selected {
            opacity: 1;

        }
    }

    [class*="MuiPaginationItem-previousNext"] {
        opacity: 1;
        background-color: #D5DCE5 !important;
        color: #000 !important;
    }
}

.pill {
    display: inline-block;
    padding: 0.25em 0.5em;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: 5px;
    color: "black";
}

.pill-warning {
    background-color: #ffe7c78c;
    color: red !important;

}

.pill-success {
    background-color: #84eab38c;
}

.black {
    color: #000;
}