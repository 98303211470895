.appbar-main-cls {
  .disabled-class {
    pointer-events: none;  
    opacity: 0.6;         
    color: grey;         
}
  .jira-top-search-bar {
    width: 300px !important;
    height: 40px !important;
    border-radius: 8px !important;
    background-color: #F4F4F4 !important;
    margin-left: 150px !important;
    border: 0px !important;

    [class*="MuiOutlinedInput"] {
      border: 0px !important;
      box-sizing: border-box !important;
      background-color: transparent !important;

    }

    input {
      background-color: transparent !important;

      &:hover,
      &:focus,
      &:focus-visible,
      &:active,
      &-internal-autofill-selected {
        background-color: transparent !important;
      }

      &:-webkit-autofill,
      &:autofill {
        -webkit-box-shadow: none !important;
        box-shadow: none !important;
        background-color: none !important;
      }

    }

    input:-webkit-autofill,
    input:-webkit-autofill {
      -webkit-box-shadow: transparent !important;
      -webkit-text-fill-color: #000;
      background-color: transparent !important;
      background-image: none;
    }

    input:-internal-autofill-selected {
      background-color: transparent !important;
    }

    &>button.css-78trlr-MuiButtonBase-root-MuiIconButton-root {
      color: #60A630 !important;
      background-color: transparent !important;
    }

    @media screen and (min-width: 576px) and (max-width: 1024px) {
      width: 300px !important;
      margin-left: 0px !important;
      display: flex !important;
    }

    @media screen and (max-width: 575px) {
      display: none !important;
    }
  }

  a.active {
    color: #60A630;
  }

  .nav-dropdown-links>button.active {
    color: #60A630 !important;
  }

  a.navbar-nav-links-name.active {
    color: #60A630 !important;
  }
}

.projects-menu {
  max-height: 270px;
  overflow-y: auto;

  .MuiMenuItem-root {
    display: flex;
    align-items: center;
  }
}

.nav-menu-item {
  a.navbar-nav-links-name {
    text-decoration: none !important;
    color: black !important;

    &:hover {
      background-color: #F0F7EB !important;
      color: #60A630 !important;
    }
  }
}

.active-menu-link-item-cls {
  color: #60A630 !important;
  background-color: #F0F7EB !important;
  border-left: 6px solid #60A630 !important;

  &:hover {
    background-color: #F0F7EB !important;
    border-left: 6px solid #60A630 !important;
    color: #60A630 !important;
  }
}