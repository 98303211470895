@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,opsz,wght@0,6..12,200..1000;1,6..12,200..1000&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto+Condensed:ital,wght@0,100..900;1,100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&family=Roboto+Condensed:ital,wght@0,100..900;1,100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@300..700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Outfit:wght@100..900&family=Space+Grotesk:wght@300..700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Figtree:ital,wght@0,300..900;1,300..900&family=Outfit:wght@100..900&family=Space+Grotesk:wght@300..700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Figtree:ital,wght@0,300..900;1,300..900&family=Lexend+Deca:wght@100..900&family=Outfit:wght@100..900&family=Space+Grotesk:wght@300..700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Onest:wght@100..900&display=swap");

body {
  margin: 0px;
}

* {
  /* font-family: 'Segoe UI', Arial, sans-serif !important; */
  /* font-family: "Poppins", sans-serif !important; */
  /* font-family: "Nunito Sans", sans-serif !important; */
  /* font-family: "Roboto Condensed", sans-serif !important; */
  /* font-family: "Open Sans", sans-serif !important; */
  /* font-family: "Space Grotesk", sans-serif !important; */
  /* font-family: "Outfit", sans-serif !important; */
  /* font-family: "Figtree", sans-serif !important; */
  /* font-family: "Lexend Deca", sans-serif !important; */
  font-family: "Onest", sans-serif !important;
}

p,
button,
/* div, */
h6,
a,
ul li,
li,
ol li,
span,
td {
  font-size: 0.9rem !important;
  /* color: rgb(42, 42, 42) !important; */
}

/* p::first-letter, button::first-letter, div::first-letter, h6::first-letter, a::first-letter, ul li::first-letter, li::first-letter, ol li::first-letterr, span::first-letter {
  text-transform: uppercase !important;
} */
p::first-letter {
  text-transform: uppercase !important;
}

button::first-letter {
  text-transform: uppercase !important;
}

div::first-letter {
  text-transform: uppercase !important;
}

a::first-letter {
  text-transform: uppercase !important;
}

ul li::first-letter {
  text-transform: uppercase !important;
}

button svg {
  font-size: 1rem !important;
}

@media screen and (max-width:850px) {

  p,
  button,
  h6,
  a,
  ul li,
  li,
  ol li,
  span,
  td {
    font-size: 0.75rem !important;

  }

  button svg {
    font-size: 0.75rem !important;
  }
}

@media screen and (max-width:450px) {

  p,
  button,
  h6,
  a,
  ul li,
  li,
  ol li,
  span,
  td {
    font-size: 0.65rem !important;

  }
}

/* span {
  font-size: 0.8rem !important;
} */
/* ::-webkit-scrollbar {
  width: 9px !important;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 18px rgba(162, 161, 160, 0.3) !important;
  -webkit-border-radius: 6px;
}

::-webkit-scrollbar-thumb {
  -webkit-border-radius: 6px;
  border-radius: 8px;
  background: #a0a0a0;
  width: 8px !important;
}

::-webkit-scrollbar-thumb:hover {
  background: #6c6c6c;
}

.crumb-class-name:hover {
  text-decoration: underline !important;
  color: #686868 !important;
}

::-webkit-scrollbar:horizontal {
  height: 5px !important;
}

::-webkit-scrollbar-thumb:horizontal {
  border-radius: 5px;
} */
/* Target only the vertical scrollbar */
::-webkit-scrollbar:vertical {
  width: 9px;
  /* Width for vertical scrollbar */
}

::-webkit-scrollbar-track:vertical {
  -webkit-box-shadow: inset 0 0 18px rgba(162, 161, 160, 0.3);
  border-radius: 6px;
}

::-webkit-scrollbar-thumb:vertical {
  border-radius: 8px;
  background: #a0a0a0;
}

::-webkit-scrollbar-thumb:vertical:hover {
  background: #6c6c6c;
}

/* Crumb hover class */
.crumb-class-name:hover {
  text-decoration: underline !important;
  color: #686868 !important;
}

.anchor-tag {
  color: #60a630 !important;
}

.Avatar_PopOver_Box {
  padding: 5px 10px;
  background-color: #faf9f9 !important;
  box-shadow: 0 0 8px grey !important;
}

#Avatar_PopOver_List {
  display: flex;
  align-items: center;
  gap: 5px;
}

.onAvatar {
  cursor: pointer;
  height: 35px !important;
  width: 35px !important;
}

.onAvatar:hover {
  z-index: 3;
}

.AvtarSelected {
  border: 2.5px solid rgb(222, 70, 164) !important;
}

.customAvtarSize {
  height: 124px !important;
  width: 124px !important
}


[class*="MuiTooltip-tooltip"] {
  font-size: 0.6rem !important;
  margin-top: 6px !important;
}

.showmoreMembers {
  margin-left: -8px !important;
}


.ck.ck-icon {
  width: 18px;
}

.hover-disable {
  cursor: no-drop !important;
}

@keyframes bounceZoom {
  0% {
    transform: scale(1);
  }
  25% {
    transform: scale(1.06);
  }
  50% {
    transform: scale(1.09);
  }
  75% {
    transform: scale(1.06);
  }
  100% {
    transform: scale(1);
  }
}

.btn-animation{
  animation: bounceZoom 6s infinite;
  background-color:#f6dddd;
  border-radius: 6px;
}