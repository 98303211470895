.access-text {
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 16px;

}

.project-settings-page {
  width: '100%';
  min-height: 100vh;

  // overflow: hidden !important;
  .project-members-table-cls {

    @media screen and (max-width:576px) {

      [class*="MuiDataGrid-columnHeader"],
      .MuiDataGrid-cell {
        min-width: 150px !important;
      }
    }
  }

}