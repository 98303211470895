.login-page-cls,
.register-page-cls {
    background: linear-gradient(30deg, #d5f0d3 15%, #eff3d9 40%, #d5f0d3) !important;
    min-height: 90vh !important;
    display: flex;
    justify-content: center;
    align-items: center !important;
}

.login-card-styles {
    /* Media query for screens with a maximum width of 767px */

    @media screen and (max-width: 767px) {
        width: 90% !important;
    }

    /* Media query for screens with a minimum width of 768px and a maximum width of 992px */
    @media screen and (min-width: 768px) and (max-width: 992px) {
        width: 60% !important;
    }
}

.error-message {
    font-size: 12px !important;
    margin-top: 0px;
}

.social-login-btns {
    color: #000000 !important;
    font-weight: 500;
    text-transform: capitalize !important;
    background-color: #efefef !important;
    border-radius: 6px !important;
    font-size: 16px !important;

    &:hover {
        background-color: #efefef !important;
    }

    img {
        width: 20px;
        height: 20px;
    }
}

.job_grid {
    margin: auto;
    padding: 2rem 0rem;

    h2 {
        font-size: 36px;
        line-height: 50px;
    }

    ul {
        margin: auto;
        padding: 0px;

        li {
            font-size: 16px !important;
            list-style: none;
            line-height: 45px;
        }
    }

    .btn-cost {
        background-color: #0A2640;
        padding: 5px;
        border-radius: 30px;
        width: 210px;
        height: 60px;
        color: #fff;
        font-size: 18px !important;
        font-weight: 700;
    }
}

.discover-blk {
    margin: auto;
    background: #E7EAEC;
    padding: 2rem 0rem;

    h2 {
        font-size: 36px;
        line-height: 50px;
    }

    ul {
        margin: auto;
        padding: 0px;

        li {
            font-size: 16px !important;
            list-style: none;
            line-height: 45px;
            background: #ffffff;
            padding: 5px 15px;
            margin: 20px 0px;
            box-shadow: 1px 2px 3px #cfcfcf;
            border-radius: 4px;
        }
    }
}

.connect-job {
    margin: auto;
    padding: 2rem 0rem;

    h2 {
        font-size: 36px;
        line-height: 50px;
    }

    ul {
        margin: auto;
        padding: 0px;

        li {
            font-size: 16px !important;
            list-style: none;
            line-height: 45px;
            padding: 5px 5px;
            margin: 20px 0px;
            border-bottom: 1px solid #939393;

            .drop-ico {
                float: right;
            }
        }
    }
}

.bottom-sec {
    margin: auto;
    padding: 2rem 0rem;

    .bottom-inn {
        background-color: #1C3D5B;
        border-radius: 40px;
        padding: 4rem;

        h2 {
            font-size: 44px;
            color: #fff;
            text-align: center;
        }

        input {
            border-radius: 30px;
            color: #333;
            font-size: 16px;
            border: 0px;
            padding: 12px 20px;
        }

        .info-btn {
            background-color: #65E4A3;
            color: #333;
            border-radius: 30px;
            font-size: 16px;
            border: 0px;
            padding: 12px 20px;

        }
    }
}


.Login-btn,
.register-btn {
    color: #fff;
    background-color: #60a630 !important;
    border-radius: 6px !important;
    text-transform: capitalize !important;
    font-size: 16px !important;

    &:hover {
        color: #fff !important;
        background-color: #60a630 !important;
    }
}

.login-now-text {
    text-decoration: underline !important;

    &:hover {
        color: #60a630 !important;
    }
}

.bg-contained-btn-cls {
    background-color: #69E6A6 !important;
    color: #0A2640 !important;
    border-radius: 20px !important;

    &:hover {
        background-color: #fff !important;
        color: #0A2640 !important;

    }
}

.servies {
    margin: auto;
    padding: 2rem 0rem;

    h1 {
        width: 65%;
        text-align: center;
        margin: 0 auto;

        @media screen and (max-width:768px) {
            width: 90%;
        }

    }

    h2 {
        font-size: 24px;
        margin-top: 20px;
    }

    ul {
        padding: 0px;

        li {
            list-style: none;
            text-decoration: underline;
        }
    }
}

.footer-bottom-links {
    li {
        list-style-position: inside;

        a {
            position: relative;
            left: -10px;
            color: #fff !important;
        }
    }
}

.box-container_absolute {
    border: 3px solid #fff;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    align-content: center;
    padding: 5px;
    background-color: #d6f5d6;
    width: 140px;
    height: 140px;

    .BackgroundLetterAvatarsLogo {
        width: 100%;
        height: 100%;
        border-radius: 50%;
        overflow: hidden;
    }
}

.profile-details_main {
    display: flex;
    flex-direction: column;
    width: 100%;

    .detail-row {
        display: flex;
        align-items: center;
        cursor: pointer;
        position: relative;
    }

    .editable-textfield {
        width: 100%;
        height: auto;
    }

    .detail-text-container {
        display: flex;
        align-items: center;
        width: 100%;
        position: relative;
    }

    .detail-text {
        width: 100%;
        height: auto;
        padding: 8px;
    }

    .edit-icon {
        display: none;
        position: absolute;
        right: 0;
        cursor: pointer;
        color: #6c757d;
    }

    .detail-row:hover .edit-icon {
        display: block; // Show the icon on hover
    }

    .detail-row:hover .editable-textfield,
    .detail-row:hover .detail-text {
        background-color: #f1f2f4;
    }

    .detail-row.editing .editable-textfield {
        background-color: #f1f2f4;
    }

    .detail-row.editing .detail-text {
        background-color: #f1f2f4;
    }
}

.manage-account-button {
    background-color: #f1f2f4 !important;
    margin-top: 40px !important;
    color: black !important;
    width: 100% !important;
    height: 30px !important;

    &:hover {
        background-color: #f1f2f4 !important;
    }
}

.custom-checkbox {
    color: #4bade8 !important;

    // &.Mui-checked {
    //     color: #319233;
    // }
}

.textFieldBtn-container-main {
    position: relative;
    display: flex;
    flex-direction: column;

    .editable-textfield {
        margin-left: 8px;
    }

    .button-container {
        position: absolute;
        top: 100%;
        right: 0;
        display: flex;
        gap: 8px;
        margin-top: 8px;
        z-index: 1;

        .button {
            background-color: white;
            color: black;
            border-color: transparent;
            width: 30px;
            min-width: 30px;
            height: 30px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 16px;

            &:hover {
                background-color: #f5f5f5;
            }
        }
    }
}

h1,
h2,
h4 {
    @media screen and (max-width:576px) {
        font-size: 1.5rem !important;
    }
}