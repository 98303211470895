@media (max-width: 767px) {
  .complete-remainingdays-buttongroup {
    width: 100% !important;
  }
}

.custom-scroll {
  max-height: 400px;
  overflow-y: scroll;
  scrollbar-width: none;
}

.custom-scroll::-webkit-scrollbar {
  display: none;
}


.task-item {
  cursor: move;
  transition: all 0.2s ease-in-out;
}


.task-title-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.edit-icon .delete-icon {
  margin-left: 6px;
  cursor: pointer;
  opacity: 0;
  transition: opacity 0.3s;

}

.task-status-icon {
  font-size: 14px !important;
}

.task-item-wrapper:hover .edit-icon {
  opacity: 1;
}

.search-icon-btn {
  background-color: transparent !important;

  &:hover {
    background-color: transparent !important;
  }
}

.active-sprint-main-cls {
  width: '100%';
  overflow: hidden;

  .each-task-status-title-cls {
    // font-size: 12px;
    font-weight: 400;
    line-height: 14.4px;
    text-transform: capitalize;
    color: #262626;
  }

  .each-task-status-card {
    position: relative;
    padding: 8px;
    border-radius: 8px;
    transition: box-shadow 0.3s ease;
  }

  .each-task-status-card:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }

  .icon-container {
    display: none;
    justify-content: center;
    align-items: center;
  }

  .each-task-status-card:hover .icon-container {
    display: flex;
  }

  .edit-icon,
  .delete-icon {
    cursor: pointer;
  }


  .each-tasks-cls {
    // font-size: 14px;
    font-weight: 700;
    color: #262626;
    line-height: 16.8px;
  }

  .issues-accordian-main-cls {
    background-color: transparent !important;
    box-shadow: none !important;

    .issues-accordian-summary-cls {
      padding: 0px !important;
      align-items: center;

      // position: relative;
      &.Mui-expanded {
        min-height: 20px !important;
      }

      [class*="MuiAccordionSummary-content"] {
        align-items: center;
        margin: 0px !important;
      }

      // [class*="MuiAccordionSummary-expandIconWrapper"] {
      //   // position: absolute;
      //   // left: 0;
      // }
    }
  }

  .empty-spint-card-cls {
    background-color: #f4f6f9;
    border: 1px solid #cccccc;
    border-radius: 15px;
    display: flex;
    justify-content: center;
    align-items: center;

    .empty-spint-card-title {
      font-weight: 700;
    }

    img {
      object-fit: contain;
      object-position: center;
    }

    .empty-spint-card-para {
      // font-size: 13px;
    }

    .empty-spint-card-btn {
      background-color: #cccccc;
      border: 0px;
      border-radius: 8px;
      // font-size: 12px;
      font-weight: 600;
    }
  }

  .active-sprints-create-issue-btn-cls {
    font-size: 12px;
    font-weight: 600;
    width: 100%;
    text-align: start;
    border: 0px;
    background-color: transparent;
    border-radius: 0px;
    opacity: 0;
  }

  .sprint-grid-layout-container-cls {
    min-height: 48vh !important;
    display: flex;
    //align-items: center;
  }

  .sprint-grid-layout-create-issue-btn {
    font-size: 8px !important;
    min-width: 30px !important;
    background-color: #60A630 !important;
    height: 25px;
  }

  .sprint-grid-layout-create-issue-input {
    font-size: 10px !important;
  }

  .task-card-hover-cls {
    height: 100% !important;
    display: flex;
    flex-direction: column;
    justify-content: flex-start !important;
    border-radius: 5px !important;

    &:hover {
      .active-sprints-create-issue-btn-cls {
        background-color: #f0f0f0;
        display: block !important;
        border-radius: 5px !important;
        opacity: 1 !important;

        &:hover {
          background-color: #69e6a680;
        }
      }
    }

  }

  .task-title {
    font-size: 12px !important;
    font-weight: 600 !important;
    color: #010101 !important;
    cursor: pointer;
    margin-bottom: 8px;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    word-wrap: break-word;
    hyphens: auto;
    -webkit-hyphens: auto;
    -webkit-word-wrap: break-word;
    overflow-wrap: break-word;
    text-overflow: ellipsis;
    white-space: normal;


    &:hover {
      text-decoration: underline;
    }
  }

  .task-card-avatar-cls {
    background-color: #60A630 !important;
    width: 25px !important;
    height: 25px !important;
    cursor: pointer;
    z-index: 2 !important;

    .persion-add-icon {
      width: 0.6em !important;
      height: 0.6em !important;
    }

  }


}

.issue-type-menu-list-main-cls {
  background-color: rgb(231, 237, 254);
  border-radius: 4px;
  padding: 4px;
  // width: 115px;

  button {
    span {
      margin-left: 0px !important;

      svg {
        color: #60A630 !important;
        fill: #60A630 !important;
      }
    }
  }
}

.create-issue-type-menu-list-main-cls {
  background-color: transparent;
  border-radius: 4px;
  padding: 4px;
  // width: 115px;

  button {
    span {
      margin-left: 0px !important;

      svg {
        color: #60A630 !important;
        fill: #60A630 !important;
      }
    }
  }
}

.issue-type-icon-container {
  // border-radius: 3px;
  // padding-left: 2px;
  // padding-right: 2px;
  font-size: inherit !important;
  cursor: pointer;

  .issue-type-icon {
    // font-size: 1rem !important;
    width: 0.9rem !important;
    height: 0.9rem !important;

  }
}

.select-issue-type-btn {
  min-width: 20px !important;
  padding: 0px !important;
  font-size: 10px !important;
  background-color: transparent !important;

  &:hover {
    background-color: transparent !important;
  }
}

.backlogIssue_model_main_cls {
  position: relative;
  margin-top: 16px;
  height: auto;
  background-color: white;
  padding: 17px;
  // box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;

  .backlogIssue_model_sticky-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: sticky;
    top: 0;
    background-color: white;
    z-index: 1;
    padding-bottom: 16px;
  }

  .backlogIssue_model_flex-center {
    display: flex;
    align-items: center;
  }

  .backlogIssue_model_textfield {
    width: 200px;
    height: 40px;
  }

  .backlogIssue_model_textfield .MuiOutlinedInput-root {
    height: 100%;
    border-color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f5f5f5;
  }

  .backlogIssue_model_textfield .MuiOutlinedInput-notchedOutline {
    border-color: transparent;
  }

  .backlogIssue_model_typography_start {
    font-weight: bold;
    font-size: 13px;
  }

  .backlogIssue_model_typography {
    font-weight: bold;
    font-size: 15px;
  }

  .backlogIssue_model_list-item {
    list-style-type: none;
    padding-left: 0;
  }

  .backlogIssue_model_list-item li {
    margin-top: 12px;
  }

  .backlogIssue_model_list-item li:first-of-type {
    margin-top: 0;
  }
}

.clear-filters-btn-cls {
  background-color: transparent;
  border: 0px;
  padding: 6px 8px;

  &:hover {
    background-color: #f1b8b8;
    border-radius: 6px;

  }
}

#assignee-layout-avatar-cls {
  width: 25px !important;
  height: 25px !important;
  margin-right: 4px !important;

}

#assignee-layout-issue-card-avatar-cls {
  width: 20px !important;
  height: 20px !important;
}

.issue-para-name-cls {
  font-size: 11px !important;
  color: #262626;
}

.search-bar {
  .MuiOutlinedInput-root {
    &:hover fieldset {
      border-color: #60A630 !important;
    }

    &.Mui-focused fieldset {
      border-color: #60A630 !important;
    }
  }

  .MuiInputAdornment-root {
    .MuiIconButton-root {
      background-color: transparent !important;

      &:hover {
        background-color: transparent !important;
      }
    }
  }

  .MuiInputLabel-root {
    &.Mui-focused {
      color: #60A630 !important;
    }
  }
}

.text-area-field {
  --Textarea-focusedHighlight: var(--joy-palette-primary-500, #60A630) !important;
}

.custom-textfield .MuiOutlinedInput-root {
  padding: 4px;
  height: 40px;
}

.assingeeBtnDropdown_main {
  min-width: 5px !important;
  background-color: transparent !important;
  border: none !important;
  color: transparent !important;
  transition: background-color 0.3s, color 0.3s !important;

  .assingeeBtnDropdown_main:hover {
    background-color: transparent !important;
    color: transparent !important;
  }

}

.timeline-update-sprint {
  font-size: small !important;
}

.navlink {
  color: #ec942c !important;
  margin-left: 7px;
}


.RightSide-popupDetails {
  font-size: 13px !important;
  margin-top: 8px !important;
}

.StoryPoint_badge {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 0 !important;
  width: 17px !important;
  height: 17px !important;
  border-radius: 50% !important;
  padding-left: 8px !important;
  padding-top: 8px !important;
  margin: 0px !important;
  background-color: #2196F3 !important;
  color: #ffffff !important;
  font-size: 10px !important;

  &:hover {
    background-color: #2196F3 !important;
  }
}

.epic-icon-cls {
  background-color: rgb(142, 33, 244);
  // padding: 3px;
  border-radius: 4px;
  font-size: 0.7rem !important;
}

.backlog-page-btn-grps-mobile-cls {
  @media (max-width:576px) {
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }
}

.backlog-header-mobile-cls {
  @media (max-width:767px) {
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }
}

.swimline-cls {
  cursor: grab;
}

.sprint-duration-alert-cls {
  @media (max-width:767px) {
    margin-left: 10px !important;
  }
}