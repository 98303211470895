table,tr,td{
    background-color: transparent !important;
    border: 0px !important;
    
}

#backlog-table-row:hover{
    background-color: transparent !important;
    border-bottom: 1px !important;
    border-style: solid !important;
    border-color: #b6b7b8 !important;
}

#backlog-table-row{
    padding-left: 15px !important;
    padding-right: 15px !important;
    border-bottom: 1px !important;
    border-style: solid !important;
    border-color: #E3E4E9 !important;
}


.assingeeBtnDropdownBacklog_main {
    min-width: 5px !important;
    background-color: transparent !important;
    border: none !important; 
    color: transparent !important;
    transition: background-color 0.3s, color 0.3s !important;
  }

  .assingeeBtnDropdownBacklog_main:hover {
    background-color: transparent !important;
    color:transparent !important; 
  }

  .descriptionText {
    font-size: 10px !important;
    color: #7B7C7B !important;
    font-style: italic !important;
    overflow: hidden !important;
    white-space: normal !important;
    word-break: break-word !important;
    width: 100% !important;
    display: -webkit-box !important;
    -webkit-box-orient: vertical !important;
    -webkit-line-clamp: 3 !important;
  }
  
  .descriptionImage {
    display: flex !important;
    flex-direction: row !important;
    flex-wrap: wrap !important;
    align-items: center !important;
    gap: 8px !important;
    width: 100% !important;
  }
  