.css-cveggr-MuiListItemIcon-root {
    min-width: 36px !important;
}

a {
    text-decoration: none !important;
    color: black !important;

    &.active {
        &>li {
            background-color: #F0F7EB !important;
            border-right: 5px solid #60A630 !important;

            &>div>div {
                color: #60A630 !important;

                span {
                    font-weight: 700 !important;
                    color: #60A630 !important;
                }
            }
        }
    }
}

.secondaryTypography {
    color: gray !important;
    font-size: 10px !important;
    font-style: italic;
}

.project-title-list-btn-cls {
    cursor: text !important;

    &:hover {
        background-color: transparent !important;
    }
}

.side-menu-close-and-open-cls {
    position: absolute !important;
    top: 25px;
    right: 0;
    background-color: #fff !important;
    z-index: 1202 !important;
    padding: 4px !important;

    svg {
        font-size: 1.6rem !important;
    }

    &:hover {
        background-color: #fff !important;
    }
}

.mobile-side-drawer-cls {
    @media screen and (max-width:991px) {
        position: fixed !important;
        left: 0px !important;
        z-index: 1199 !important;
    }
}

.main-component-mobile-cls {
    @media screen and (max-width:991px) {
        margin-left: 70px !important;
    }
}

.mobile-overlay-container {
    @media screen and (max-width:991px) {
        position: fixed;
        left: 0px;
        z-index: 1198;
        width: 100%;
        height: 100vh;
        background-color: rgba(0, 0, 0, 0.5);
    }

}